/** @jsx jsx */
import { jsx } from "theme-ui";
import Container from "@components/Container";
import { rem } from "@src/theme";
import React from "react";
import { useCraft } from "./query";
import anu from "@src/assets/images/anu.png";
import swinburne from "@src/assets/images/swinburne.jpg";
import unsw from "@src/assets/images/unsw.png";
import uqld from "@src/assets/images/uqld.png";
import uts from "@src/assets/images/uts.jpg";
import griffith from "@src/assets/images/griffith.jpg";
import lordmayor from "@src/assets/images/lordmayor.png";
import today from "@src/assets/images/today.png";
import vicgov from "@src/assets/images/vicgov.png";
import acpns from "@src/assets/images/acpns.png";
import csiunsw from "@src/assets/images/csi-unsw.jpg";

import ScrollContainer from "@components/ScrollContainer";
import ContentTemplate from "@components/ContentTemplate";

const About = ({ location }) => {
  const { title, description, body } = useCraft();

  return (
    <ContentTemplate
      title={title}
      description={description}
      body={body}
      bgIcon="book"
      rest={<Partners />}
      location={location}
    />
  );
};

const partners = [
  { img: anu, alt: "anu", link: "https://www.anu.edu.au/" },

  { img: unsw, alt: "unsw", link: "https://www.unsw.edu.au/" },
  { img: uqld, alt: "uqld", link: "https://www.uq.edu.au/" },
];
const supporters = [
  { img: lordmayor, alt: "lordmayor", link: "https://www.lmcf.org.au/" },
  { img: vicgov, alt: "vicgov", link: "https://djpr.vic.gov.au/" },
];

const poweredBy = [
  { img: today, alt: "today", link: "https://today.design/" },
  {
    img: swinburne,
    alt: "swinburne",
    link:
      "https://www.swinburne.edu.au/research/centres-groups-clinics/centre-for-social-impact-swinburne/",
  },
  {
    img: griffith,
    alt: "Griffith University Yunus Centre ",
    link: "https://www.griffith.edu.au/griffith-business-school/yunus-centre",
  },
  {
    img: acpns,
    alt: "Griffith University Yunus Centre ",
    link: "www.qut.edu.au/business/acpns",
  },
  {
    img: uts,
    alt: "University of Technology Sydney",
    link: "https://www.uts.edu.au/",
  },
  { img: csiunsw, alt: "CSI UNSW", link: "https://www.csi.edu.au/" },
];

const Partners = () => (
  <React.Fragment>
    <section
      sx={{
        mt: [rem(20), null, rem(40)],
      }}
    >
      <Container>
        <h1
          sx={{
            variant: "typography.headingXL",
          }}
        >
          Funded by
        </h1>
      </Container>
      <ScrollContainer>
        <ul
          sx={{
            width: ["calc(100vh + 50px)", "100%", "100%"],
            display: "flex",
          }}
        >
          {supporters.map(({ img, alt, link }) => (
            <a
              key={alt}
              href={link}
              target="_blank"
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                sx={{
                  mb: rem(20),
                }}
              >
                <img
                  src={img}
                  alt={alt}
                  sx={{
                    mr: rem(50),
                    width: rem(100),
                  }}
                />
              </div>
            </a>
          ))}
        </ul>
      </ScrollContainer>
    </section>
    <section
      sx={{
        mt: [rem(20), null, rem(40)],
      }}
    >
      <Container>
        <h1
          sx={{
            variant: "typography.headingXL",
          }}
        >
          Powered by
        </h1>
      </Container>
      <ScrollContainer>
        <ul
          sx={{
            width: ["calc(100vh + 50px)", "100%", "100%"],
            display: "flex",
          }}
        >
          {poweredBy.map(({ img, alt, link }) => (
            <a
              key={alt}
              href={link}
              target="_blank"
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                sx={{
                  mb: rem(20),
                  mr: rem(50),
                }}
              >
                <img
                  src={img}
                  alt={alt}
                  sx={{
                    width: rem(200),
                  }}
                />
              </div>
            </a>
          ))}
        </ul>
      </ScrollContainer>
    </section>
  </React.Fragment>
);
export default About;
