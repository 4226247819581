/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import Container from "@components/Container";
import { useState, useEffect } from "react";
interface ComponentProps {
  children: React.ReactNode;
}

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

const ScrollContainer = ({ children }: ComponentProps) => {
  const size = useWindowSize();
  const [marginLeft, setMarginLeft] = useState("0px");

  useEffect(() => {
    if (window) {
      const style = window.getComputedStyle(
        document.getElementsByClassName("dummy-container")[0]
      );

      setMarginLeft(style.getPropertyValue("margin-left"));
    }
  }, [size.width]);

  return (
    <React.Fragment>
      <div
        sx={{
          display: ["none", null, "block"],
        }}
      >
        <Container>{children}</Container>
      </div>
      <div
        sx={{
          display: ["block", null, "none"],
          maxWidth: "1500px",
          width: ["100%", null, "90%"],
          pl: marginLeft,
          overflowX: "scroll",
          "li:last-child": {
            pr: "4%",
          },
        }}
      >
        {children}
      </div>
      <Container customClass="dummy-container">
        <div></div>
      </Container>
    </React.Fragment>
  );
};

export default ScrollContainer;
